import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"
import FormFirst from "../form"
import YMaps from "../Ymaps"

const Contact = () => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      phone: allSanityPhone {
        edges {
          node {
            phone
          }
        }
      }
      mail: allSanityEMail {
        edges {
          node {
            name
          }
        }
      }
      address: allSanityAddress {
        edges {
          node {
            address
          }
        }
      }
      social: allSanitySocial {
        edges {
          node {
            name
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Columns rowReverse>
        <Column is12>
          <YMaps />
        </Column>

        <Column is7>
          <Box>
            <Columns>
              <Column is6>
                <div className="icon-text has-family-pt-sans has-text-weight-bold mb-05">
                  <span className="icon radius-50 has-background-primary">
                    <FontAwesomeIcon
                      icon="location-arrow"
                      size="sm"
                      color="#000"
                    />
                  </span>
                  <span>Адрес</span>
                </div>
                {data.address.edges.map((item, i) => (
                    <p key={i} className="block mb-1">
                      {item.node.address}
                    </p>
                ))}
              </Column>
              <Column is6>
                <div className="icon-text has-family-pt-sans has-text-weight-bold mb-05">
                  <span className="icon radius-50 has-background-primary">
                    <FontAwesomeIcon icon="phone" size="sm" color="#000" />
                  </span>
                  <span>Номер телефона</span>
                </div>
                <p className="block has-text-link">
                  {data.phone.edges.map((item, i) => (
                      <>
                        <a key={i} href={`tel:${item.node.phone}`}>{item.node.phone}</a>
                        <br/>
                      </>
                  ))}
                </p>
              </Column>
              <Column is6>
                <p className="has-text-weight-bold has-family-pt-sans mb-05">
                  Корпоративная Почта
                </p>
                <div className="icon-text mr-1">
                  <span className="icon radius-50 has-background-primary">
                    <FontAwesomeIcon
                      icon="envelope-open-text"
                      size="sm"
                      color="#000"
                    />
                  </span>
                  {data.mail.edges.map((item, i) => (
                      <span key={i}>{item.node.name}</span>
                  ))}
                </div>
              </Column>
              <Column is6>
                <p className="has-text-weight-bold has-family-pt-sans mb-05">
                  Мы в социальных сетях
                </p>
                {data.social.edges.map((item, i) => (
                    <a key={i} href={item.node.social} target="_blank">
                      <div className="icon-text mr-1">
                        <span>{item.node.name}</span>
                      </div>
                    </a>
                ))}
              </Column>
            </Columns>
          </Box>
        </Column>
        <Column is5 tmcentered>
          <Box>
            <FormFirst />
          </Box>
        </Column>
      </Columns>
    </Section>
  )
}

export default Contact
