import React from "react"
import Sticky from "react-sticky-el"
import SEO from "../components/SEO/index"
import Contact from "../components/Sections/Contact"
import NavBar from "../components/navbar"

const ContactPage = () => {
  return (
    <>
      <SEO title="UZMBC" description="Multi Business Company - Наши контакты" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <Contact />
    </>
  )
}

export default ContactPage
