import React from "react"
import { Formik, Form, Field } from "formik"
import axios from "axios"
import classNames from "classnames"
import * as Yup from "yup"
import PhoneInputField from "./PhoneInputField"

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "Не более 50 символов!")
    .required("Обязательное поле"),
  company: Yup.string()
    .max(50, "Не более 50 символов!")
    .required("Обязательное поле"),
  email: Yup.string()
    .max(50, "Не более 50 символов!")
    .email("Неверный формат email")
    .required("Обязательное поле"),
  phone: Yup.string().required("Обязательное поле"),
  message: Yup.string()
    .max(500, "Не более 500 символов!")
    .required("Обязательное поле"),
})

const FormFirst = () => (
  <Formik
    initialValues={{
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
    }}
    validationSchema={ContactSchema}
    onSubmit={(values, actions) => {
      const msg = encodeURIComponent(
        "<b>---------Имя---------\n\n</b>" +
          `${values.name}\n\n` +
          "<b>---------Организация---------\n\n</b>" +
          `${values.company}\n\n` +
          "<b>---------Email---------\n\n</b>" +
          `${values.email}\n\n` +
          "<b>---------Номер телефона---------\n\n</b>" +
          `${values.phone}\n\n` +
          "<b>---------Сообщение---------\n\n</b>" +
          `${values.message}\n\n`
      )
      const url = `https://api.telegram.org/bot1539411093:AAGxi2gynXGTVDITwP5NXJPlo23q0gUPceg/sendMessage?chat_id=1347204802&parse_mode=HTML&text=${msg}`
      axios
        .get(url)
        .then(function (response) {
          actions.setStatus({ msg: "Сообщение успешно отправлено" })
          setTimeout(actions.resetForm, 3000)
        })
        .catch(function (error) {
          console.log(error)
        })
    }}
  >
    {({ errors, touched, status }) => {
      return (
        <Form className="container has-family-pt-sans">
          <div className="field mb-1">
            <div className="control">
              <label className={classNames("label")}>Имя *</label>
              <Field
                name="name"
                id="name"
                className={classNames(
                  "input is-medium has-family-pt-sans is-size-6-mobile"
                )}
              />
              {errors.name && touched.name ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.name}
                </p>
              ) : null}
            </div>
          </div>
          <div className="field mb-1">
            <div className="control">
              <label className={classNames("label")}>Организация *</label>
              <Field
                type="company"
                name="company"
                id="company"
                className={classNames(
                  "input is-medium has-family-pt-sans is-size-6-mobile"
                )}
              />
              {errors.company && touched.company ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.company}
                </p>
              ) : null}
            </div>
          </div>

          <div className="field mb-1">
            <div className="control">
              <label className={classNames("label")}>Email *</label>
              <Field
                type="email"
                name="email"
                id="email"
                className={classNames(
                  "input is-medium has-family-pt-sans is-size-6-mobile"
                )}
              />
              {errors.email && touched.email ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.email}
                </p>
              ) : null}
            </div>
          </div>
          <div className="field mb-1">
            <div className="control">
              <label className={classNames("label")}>Номер телефона *</label>
              <Field
                className={classNames("input is-medium has-family-pt-sans")}
                name="phone"
                component={PhoneInputField}
              />
              {errors.phone && touched.phone ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.phone}
                </p>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label className={classNames("label")}>
                Ваше обращение, жалоба и предложение *
              </label>
              <Field
                component="textarea"
                name="message"
                id="message"
                className={classNames("textarea")}
              />
              {errors.message && touched.message ? (
                <p className="is-size-7 has-text-primary has-text-weight-semibold">
                  {errors.message}
                </p>
              ) : null}
            </div>
          </div>

          {status && status.msg && (
            <p className="has-text-weight-900 has-text-success mb-1 has-text-centered">
              Ваша заявка принята
            </p>
          )}
          <div className="control has-text-centered-mobile">
            <button
              type="submit"
              class="button hvr-bounce-to-top is-size-6-mobile has-text-weight-bold is-medium is-success is-outlined mb-2"
            >
              <strong>Отправить</strong>
            </button>
          </div>
        </Form>
      )
    }}
  </Formik>
)

export default FormFirst
